<template>
<div class="profile-view">
    <PtyBack @back="$router.go(-1)" />
    <img class="photo" :src="$route.params.participant.pictureUrl" alt="Profile picture" />

    <div class="details">
        <PtyButton id="pty__c8e7qcl63" primary class="tag-button" >
            <img :src="tags[$route.params.participant.tag]" alt="Devil" style="height:1.5rem; margin-right: 0.5rem;"/>
            <!-- show tag as lowercase except for first letter, that must be uppercase-->
            {{ $route.params.participant.tag.charAt(0).toUpperCase() + $route.params.participant.tag.slice(1).toLowerCase() }}
        </PtyButton>

        <vs-row direction="column" style="gap: 0.5rem;">
            <h2 style="margin:0;">{{ $route.params.participant.nickname }}, {{ $route.params.participant.age }}</h2>
            <vs-row align="center">
                <i class="bx bxs-navigation " style="color: #9F9F9F; margin-right: 0.2rem;"></i>
                <p style="margin:0;">{{ $route.params.participant.city }}</p>

            </vs-row>
        </vs-row>
        <PtySelector :options="possibleGenders" v-model="selectedGender" :value="selectedGender" colorizeMode="stroke" />

        <!--<vs-row direction="column" style="gap: 1rem;">
            <h3 style="font-size:1.3rem; margin:0;">Bio</h3>
            <p style="margin:0;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
        </vs-row>-->
        
    </div>
</div>
</template>

<script>
import PtyBack from '../components/PtyBack.vue';
import PtyButton from '../components/PtyButton.vue';
import PtySelector from '@/components/PtySelector.vue';
import CheckIcon from '../assets/images/icons/check.svg';

import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';


export default {
    name: 'Reservation',
    components: {
        PtyButton,
        PtyBack,
        PtySelector
    },

    computed: {
        possibleGenders() {
            console.log(this.$route.params.participant)
            return [
                { id: 'MALE', title: this.$t('GenderSelection.options.male.title'), caption: this.$t('GenderSelection.options.male.caption'), icon: CheckIcon },
                { id: 'FEMALE', title: this.$t('GenderSelection.options.female.title'), caption: this.$t('GenderSelection.options.female.caption'), icon: CheckIcon },
                { id: 'COUPLE', title: this.$t('GenderSelection.options.couple.title'), caption: this.$t('GenderSelection.options.couple.caption'), icon: CheckIcon }
            ].filter(e => e.id == this.$route.params.participant.type)
        },
        selectedGender: {
            get() {
                return this.$route.params.participant.type;
            },
            set() {
                
            }
        }
    },
    data: () => ({
        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },
    }),

    props: {
        participant: {
            type: Object,
            required: true,
        },
    }
}
</script>

<style scoped>
.profile-view .photo {
    position: absolute;
    height: calc(100vh - 3rem);
    width: auto;
    top: -5%;
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.profile-view .details {
    position: absolute;
    bottom: 0;
    left:0;
    padding: 1.5rem;
    padding-bottom: 3rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 3.125rem 3.125rem 0rem 0rem;
    width: 100%;
    box-sizing: border-box;
}

.profile-view .details p {
    text-align: left;
    color: #9F9F9F;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02rem;
}

.profile-view .details .tag-button {
    position: absolute;
    top: -27px;
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.profile-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 0!important;
    gap: 1.5rem;
    background:
        url(../assets/images/illustrations/logos.png) top/130% auto no-repeat,
        linear-gradient(180deg, rgb(210, 251, 253) 16.57%, rgb(255, 255, 255) 53.63%);

}
</style>
